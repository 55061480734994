import React, { useCallback, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import themeConfig from '../theme'
import { withTranslation } from 'react-i18next'
import {
   calculateUsedVacationDaysForYearAtDate,
   calculateRequestedVacationDaysForYearAtDate,
   getVacationSpan,
   dayIntervalContainsDate,
} from 'novarto-time'
import moment from 'moment-timezone'
import { getVaccationDaysForDate, getEmploymentsForSpan, isThisYear } from '../util/helpers'
import clsx from 'clsx'

const { styles } = themeConfig()

const VacationPaper = props => {
   const {
      setVacationCalc,
      user,
      absences,
      requests,
      holidays,
      workDay,
      t,
      VacationRefillMMDD,
      ShowNextYearMMDD,
   } = props

   const [calc, setCalc] = React.useState([
      {
         year: 2020,
         used: { days: 0, hours: 0, mins: 0 },
         requested: 0,
         available: 0,
         saved: 0,
         span: {
            Start: '',
            End: '',
         },
         init: 0,
      },
      {
         year: 2021,
         used: { days: 0, hours: 0, mins: 0 },
         requested: 0,
         available: 0,
         saved: 0,
         span: {
            Start: '',
            End: '',
         },
         init: 0,
      },
      {
         year: 2022,
         used: { days: 0, hours: 0, mins: 0 },
         requested: 0,
         available: 0,
         saved: 0,
         span: {
            Start: '',
            End: '',
         },
         init: 0,
      },
   ])

   const calculateVacationForDate = (
      user,
      date,
      absences,
      requests,
      holidays,
      workDay,
      savedFromPreviousCalc
   ) => {
      const usedResult = calculateUsedVacationDaysForYearAtDate(
         absences,
         holidays,
         workDay,
         new Date(date),
         VacationRefillMMDD
      )
      const requestedResult = calculateRequestedVacationDaysForYearAtDate(
         requests,
         holidays,
         workDay,
         new Date(date),
         VacationRefillMMDD
      )

      const span = getVacationSpan(date, workDay, VacationRefillMMDD)
      const employmentsForDate = getEmploymentsForSpan(user.Employments, span)
      if (!employmentsForDate || employmentsForDate.length < 0) {
         return null // Means we do not have an employment for this span
      }

      let availableDays = 0
      if (user && user.Employments) {
         availableDays = getVaccationDaysForDate(user.Employments, new Date(date))
      }

      // Vacation moved from old systems for example
      let init = 0
      employmentsForDate.forEach(employment => {
         if (employment.InitialVacation) {
            if (dayIntervalContainsDate(span.Start, span.End, employment.StartDate)) {
               init += Number(employment.InitialVacation)
            }
         }
      })

      const saved =
         availableDays + savedFromPreviousCalc + init - usedResult.days - requestedResult.days

      const result = {
         span: span,
         year: date.year(),
         used: usedResult,
         requested: requestedResult,
         available: availableDays,
         saved: saved,
         savedFromPreviousCalc: savedFromPreviousCalc,
         init: init,
      }

      //console.log("RESULT: "+JSON.stringify(result));
      return result
   }

   const calculateVacation = useCallback(
      (absences, requests, user, holidays, workDay) => {
         if (!user) {
            return
         }

         let earliestDate = user.Employments.reduce((earliest, employment) => {
            let startDate = new Date(employment.StartDate)
            return earliest < startDate ? earliest : startDate
         }, new Date())

         let firstEmploymentYear = earliestDate.getFullYear()

         const today = moment(new Date())
         const currentYear = today.year()

         const calcTotal = []
         let savedVacation = 0

         for (let year = firstEmploymentYear; year <= currentYear + 1; year++) {
            const yearDate = moment(new Date()).year(year)
            const calculation = calculateVacationForDate(
               user,
               yearDate,
               absences,
               requests,
               holidays,
               workDay,
               savedVacation
            )

            if (calculation?.saved !== undefined) {
               savedVacation = calculation.saved
            }
            if (calculation) {
               calcTotal.push(calculation)
            }
         }
         const lastFourCalculations = calcTotal.slice(-4)
         setVacationCalc(lastFourCalculations)
         setCalc(lastFourCalculations)
      },
      [setVacationCalc]
   )
   useEffect(() => {
      calculateVacation(absences, requests, user, holidays, workDay)
   }, [absences, requests, user, holidays, workDay, calculateVacation])

   const PrintRow = props => {
      const { calc, classes, className, sx } = props
      if (!calc) {
         // Do not display spans that have no calculation
         return null
      }
      return (
         <React.Fragment>
            <Grid
               container
               direction="row"
               spacing={2}
               className={clsx(className === 'today' ? classes.today : '')}
               sx={sx}
            >
               <Grid
                  item
                  style={{
                     width: 150,
                     paddingTop: '8px',
                     paddingBottom: '8px',
                     display: 'grid',
                     alignItems: 'center',
                  }}
               >
                  <Grid container direction="row" alignItems="center">
                     <Typography variant="h6" style={{ fontSize: 12, fontWeight: 'bold' }}>
                        {calc.year}
                     </Typography>
                     <Typography variant="h6" style={{ fontSize: 10 }}>
                        {moment(calc.span.Start).format('YYYY-MM-DD')} {t('to')}{' '}
                        {moment(calc.span.End).format('YYYY-MM-DD')}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  item
                  style={{
                     width: 100,
                     paddingTop: '8px',
                     paddingBottom: '8px',
                     display: 'grid',
                     alignItems: 'center',
                  }}
               >
                  <Grid container direction="column" alignItems="center">
                     {calc.init > 0 ? (
                        <React.Fragment>
                           <Typography variant="h6">
                              {calc.available}{' '}
                              <span style={{ color: 'lightseagreen' }}>
                                 + {calc.init + calc.savedFromPreviousCalc}
                              </span>
                           </Typography>
                           <Typography
                              style={{ fontSize: 10 }}
                              variant="h6"
                              className={'text-center'}
                           >
                              {t('InitialVacation')}
                           </Typography>
                        </React.Fragment>
                     ) : (
                        <Typography style={{}} variant="h6">
                           {calc.savedFromPreviousCalc > 0 ? (
                              <React.Fragment>
                                 {calc.available} +{' '}
                                 <span style={{ color: 'lightseagreen' }}>
                                    {calc.savedFromPreviousCalc}
                                 </span>
                              </React.Fragment>
                           ) : (
                              <React.Fragment>{calc.available}</React.Fragment>
                           )}
                        </Typography>
                     )}
                  </Grid>
               </Grid>
               <Grid
                  item
                  style={{
                     width: 150,
                     paddingTop: '8px',
                     paddingBottom: '8px',
                     display: 'grid',
                     alignItems: 'center',
                  }}
               >
                  <Grid container direction="column" alignItems="center">
                     <Typography style={{ color: 'lightseagreen' }} variant="h6">
                        {calc.used.days}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  item
                  style={{
                     width: 150,
                     paddingTop: '8px',
                     paddingBottom: '8px',
                     display: 'grid',
                     alignItems: 'center',
                  }}
               >
                  <Grid container direction="column" alignItems="center">
                     <Typography style={{ color: 'lightseagreen' }} variant="h6">
                        {calc.requested.days}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  item
                  style={{
                     width: 150,
                     paddingTop: '8px',
                     paddingBottom: '8px',
                     display: 'grid',
                     alignItems: 'center',
                  }}
               >
                  <Grid container direction="column" alignItems="center">
                     <Typography style={{ color: 'lightseagreen' }} variant="h6">
                        {calc.saved}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </React.Fragment>
      )
   }

   const showNextYearMoment = moment(moment(new Date()).format('YYYY') + '-' + ShowNextYearMMDD)
   const todayMoment = moment(new Date())

   const twoYearsAgo = calc.find(c => c.year === todayMoment.year() - 2)
   const oneYearAgo = calc.find(c => c.year === todayMoment.year() - 1)
   const currentYear = calc.find(c => c.year === todayMoment.year())
   const nextYear = calc.find(c => c.year === todayMoment.year() + 1)

   return (
      <React.Fragment>
         <Paper
            key={'vaccationContentPaper'}
            className="contentPaper"
            style={{
               maxWidth: 900,
               paddingTop: 20,
               paddingBottom: 20,
               marginBottom: 20,
               boxShadow: 'none',
            }}
         >
            <Paper
               key={'absencePaper'}
               style={{ paddingLeft: 25, paddingRight: 25, paddingBottom: 20, boxShadow: 'none' }}
            >
               <Typography variant="h5" style={{ paddingBottom: 10, fontSize: '22px' }}>
                  {t('VacationDays')}
               </Typography>

               <Grid container direction="row" spacing={2}>
                  <Grid item style={{ width: 150 }}>
                     <Grid container direction="column" alignItems="center">
                        <Typography variant="h6" style={{ fontSize: 18, marginLeft: '-83px' }}>
                           {t('period')}
                        </Typography>
                     </Grid>
                  </Grid>
                  <Grid item style={{ width: 110 }}>
                     <Grid container direction="column" alignItems="center">
                        <Typography variant="h6" style={{ fontSize: 18 }}>
                           {t('vaccationDaysPerYear')}
                        </Typography>
                     </Grid>
                  </Grid>
                  <Grid item style={{ width: 150 }}>
                     <Grid container direction="column" alignItems="center">
                        <Typography style={{ fontSize: 18 }} variant="h6">
                           {t('usedVaccationDays')}
                        </Typography>
                     </Grid>
                  </Grid>
                  <Grid item style={{ width: 150 }}>
                     <Grid container direction="column" alignItems="center">
                        <Typography style={{ fontSize: 18 }} variant="h6">
                           {t('requestedVaccationDays')}
                        </Typography>
                     </Grid>
                  </Grid>
                  <Grid item style={{ width: 150 }}>
                     <Grid container direction="column" alignItems="center">
                        <Typography style={{ fontSize: 18 }} variant="h6">
                           {t('vaccationDaysLeft')}
                        </Typography>
                     </Grid>
                  </Grid>
               </Grid>

               <React.Fragment>
                  {!todayMoment.isAfter(showNextYearMoment) ? (
                     <PrintRow
                        {...props}
                        calc={twoYearsAgo}
                        className={isThisYear(twoYearsAgo?.year) ? 'today' : ''}
                        sx={{ marginTop: '1px' }}
                     />
                  ) : null}

                  <PrintRow
                     {...props}
                     calc={oneYearAgo}
                     className={isThisYear(oneYearAgo?.year) ? 'today' : ''}
                     sx={{ marginTop: '1px' }}
                  />
                  <PrintRow
                     {...props}
                     calc={currentYear}
                     className={isThisYear(currentYear?.year) ? 'today' : ''}
                     sx={{ marginTop: '1px' }}
                  />
                  {todayMoment.isAfter(showNextYearMoment) ||
                  (nextYear && (nextYear.used.days > 0 || nextYear.requested.days > 0)) ? (
                     <PrintRow
                        {...props}
                        calc={nextYear}
                        className={isThisYear(nextYear?.year) ? 'today' : ''}
                        sx={{ marginTop: '1px' }}
                     />
                  ) : null}
               </React.Fragment>
            </Paper>
         </Paper>
      </React.Fragment>
   )
}

export default withStyles(styles)(withTranslation()(VacationPaper))
